import React from 'react'
import { graphql } from 'gatsby'
import { Link } from 'gatsby'

import BackgroundVideo from '../components/BackgroundVideo'
import Content from '../components/Content'
import Layout from '../components/Layout'
import SEO from "../components/Seo"


// Export Template for use in CMS preview
export const HomePageTemplate = ({video, videoPoster, videoTitle, body }) => (
  <main className="Home">
    <section className="BackgroundVideo-section section">
    <BackgroundVideo poster={videoPoster} videoTitle={videoTitle}>
      {video && <source src={video} type="video/mp4" />}
    </BackgroundVideo>
    </section>

    <section className="section">
      <div className="container">
        <Content source={body} />
      </div>
    </section>

    <section className="section">
    <div className="boutons">
    <div className="taCenter third">
        <Link to='/post-categories/current-events/'>
          <div className="Button" href='/post-categories/current-events/'>
            What's On
          </div>
        </Link>
        </div>
        <div className="taCenter third">
        <Link to='/contact/'>
          <div className="Button" onClick='/contact/'>
            Get in touch
          </div>
        </Link>
        </div>
          <div className="taCenter third">
          <Link to='/donate/'>
          <div className="Button" onClick='/donate/'>
            Donate
          </div>
        </Link>
        </div>
      </div>
    </section>
  </main>
)

// Export Default HomePage for front-end
const HomePage = ({ data: { page } }) => (
  <Layout meta={page.frontmatter.meta || false}>
    <HomePageTemplate {...page} {...page.frontmatter} body={page.html} />
    <SEO title="Home | Coders of Colour" />
  </Layout>
)

export default HomePage

export const pageQuery = graphql`
  ## Query for HomePage data
  ## Use GraphiQL interface (http://localhost:8000/___graphql)
  ## $id is processed via gatsby-node.js
  ## query name must be unique to this file
  query HomePage($id: String!) {
    page: markdownRemark(id: { eq: $id }) {
      ...Meta
      html
      frontmatter {
        video
        videoPoster
        videoTitle
      }
    }
  }
`
